iframe {
  border: 0;
}

body {
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 100%;
  max-width: 375px;
}
